"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConjunctionToJSON = exports.ConjunctionFromJSONTyped = exports.ConjunctionFromJSON = exports.instanceOfConjunction = void 0;
var Formula_1 = require("./Formula");
/**
 * Check if a given object implements the Conjunction interface.
 */
function instanceOfConjunction(value) {
    var isInstance = true;
    isInstance = isInstance && "conjunction" in value;
    return isInstance;
}
exports.instanceOfConjunction = instanceOfConjunction;
function ConjunctionFromJSON(json) {
    return ConjunctionFromJSONTyped(json, false);
}
exports.ConjunctionFromJSON = ConjunctionFromJSON;
function ConjunctionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, Formula_1.FormulaFromJSONTyped)(json, ignoreDiscriminator)), { 'conjunction': (json['conjunction'].map(Formula_1.FormulaFromJSON)) });
}
exports.ConjunctionFromJSONTyped = ConjunctionFromJSONTyped;
function ConjunctionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, Formula_1.FormulaToJSON)(value)), { 'conjunction': (value.conjunction.map(Formula_1.FormulaToJSON)) });
}
exports.ConjunctionToJSON = ConjunctionToJSON;
