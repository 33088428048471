"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignedEntityIDToJSON = exports.SignedEntityIDFromJSONTyped = exports.SignedEntityIDFromJSON = exports.instanceOfSignedEntityID = void 0;
/**
 * Check if a given object implements the SignedEntityID interface.
 */
function instanceOfSignedEntityID(value) {
    var isInstance = true;
    isInstance = isInstance && "token" in value;
    return isInstance;
}
exports.instanceOfSignedEntityID = instanceOfSignedEntityID;
function SignedEntityIDFromJSON(json) {
    return SignedEntityIDFromJSONTyped(json, false);
}
exports.SignedEntityIDFromJSON = SignedEntityIDFromJSON;
function SignedEntityIDFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'token': json['token'],
    };
}
exports.SignedEntityIDFromJSONTyped = SignedEntityIDFromJSONTyped;
function SignedEntityIDToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'token': value.token,
    };
}
exports.SignedEntityIDToJSON = SignedEntityIDToJSON;
