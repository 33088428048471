"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetMFAEnrollmentsResponseToJSON = exports.GetMFAEnrollmentsResponseFromJSONTyped = exports.GetMFAEnrollmentsResponseFromJSON = exports.instanceOfGetMFAEnrollmentsResponse = void 0;
var MFAEnrollment_1 = require("./MFAEnrollment");
/**
 * Check if a given object implements the GetMFAEnrollmentsResponse interface.
 */
function instanceOfGetMFAEnrollmentsResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "enrollments" in value;
    return isInstance;
}
exports.instanceOfGetMFAEnrollmentsResponse = instanceOfGetMFAEnrollmentsResponse;
function GetMFAEnrollmentsResponseFromJSON(json) {
    return GetMFAEnrollmentsResponseFromJSONTyped(json, false);
}
exports.GetMFAEnrollmentsResponseFromJSON = GetMFAEnrollmentsResponseFromJSON;
function GetMFAEnrollmentsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'enrollments': (json['enrollments'].map(MFAEnrollment_1.MFAEnrollmentFromJSON)),
    };
}
exports.GetMFAEnrollmentsResponseFromJSONTyped = GetMFAEnrollmentsResponseFromJSONTyped;
function GetMFAEnrollmentsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'enrollments': (value.enrollments.map(MFAEnrollment_1.MFAEnrollmentToJSON)),
    };
}
exports.GetMFAEnrollmentsResponseToJSON = GetMFAEnrollmentsResponseToJSON;
