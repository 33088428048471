"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionPairToJSON = exports.PermissionPairFromJSONTyped = exports.PermissionPairFromJSON = exports.instanceOfPermissionPair = void 0;
var Permission_1 = require("./Permission");
/**
 * Check if a given object implements the PermissionPair interface.
 */
function instanceOfPermissionPair(value) {
    var isInstance = true;
    isInstance = isInstance && "action" in value;
    isInstance = isInstance && "permitted" in value;
    isInstance = isInstance && "permissibleToPermit" in value;
    return isInstance;
}
exports.instanceOfPermissionPair = instanceOfPermissionPair;
function PermissionPairFromJSON(json) {
    return PermissionPairFromJSONTyped(json, false);
}
exports.PermissionPairFromJSON = PermissionPairFromJSON;
function PermissionPairFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'action': json['action'],
        'permitted': (0, Permission_1.PermissionFromJSON)(json['permitted']),
        'permissibleToPermit': (0, Permission_1.PermissionFromJSON)(json['permissibleToPermit']),
    };
}
exports.PermissionPairFromJSONTyped = PermissionPairFromJSONTyped;
function PermissionPairToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'action': value.action,
        'permitted': (0, Permission_1.PermissionToJSON)(value.permitted),
        'permissibleToPermit': (0, Permission_1.PermissionToJSON)(value.permissibleToPermit),
    };
}
exports.PermissionPairToJSON = PermissionPairToJSON;
