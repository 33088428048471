// Copyright 2022 Merit International Inc. All Rights Reserved

import { UnreachableCaseError } from "../errors/UnreachableCaseError";
import jwt_decode from "jwt-decode";

// False positive no-shadow
// eslint-disable-next-line no-shadow
export enum RoleType {
  AdminRole = 1,
  ManagerRole = 2,
  ViewerRole = 3,
  TabletOperatorRole = 4,
}

type JWTContents = {
  readonly claims: { readonly role: RoleType };
};

export const userHasSufficientPermission = (jwt: string, expectedRole: RoleType): boolean => {
  const {
    claims: { role },
  }: JWTContents = jwt_decode(jwt);

  switch (role) {
    case RoleType.AdminRole:
      return true;
    case RoleType.ManagerRole:
      return expectedRole !== RoleType.AdminRole;
    case RoleType.ViewerRole:
      return expectedRole === RoleType.ViewerRole;
    case RoleType.TabletOperatorRole:
      return expectedRole === RoleType.TabletOperatorRole;
    default:
      throw new UnreachableCaseError(role);
  }
};
