"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidationErrorResultToJSON = exports.ValidationErrorResultFromJSONTyped = exports.ValidationErrorResultFromJSON = exports.instanceOfValidationErrorResult = void 0;
var runtime_1 = require("../runtime");
var Atom_1 = require("./Atom");
/**
 * Check if a given object implements the ValidationErrorResult interface.
 */
function instanceOfValidationErrorResult(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfValidationErrorResult = instanceOfValidationErrorResult;
function ValidationErrorResultFromJSON(json) {
    return ValidationErrorResultFromJSONTyped(json, false);
}
exports.ValidationErrorResultFromJSON = ValidationErrorResultFromJSON;
function ValidationErrorResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'templateFieldID': !(0, runtime_1.exists)(json, 'templateFieldID') ? undefined : json['templateFieldID'],
        'templateID': !(0, runtime_1.exists)(json, 'templateID') ? undefined : json['templateID'],
        'fieldKindID': !(0, runtime_1.exists)(json, 'fieldKindID') ? undefined : json['fieldKindID'],
        'atom': !(0, runtime_1.exists)(json, 'atom') ? undefined : (0, Atom_1.AtomFromJSON)(json['atom']),
        'message': !(0, runtime_1.exists)(json, 'message') ? undefined : json['message'],
    };
}
exports.ValidationErrorResultFromJSONTyped = ValidationErrorResultFromJSONTyped;
function ValidationErrorResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'templateFieldID': value.templateFieldID,
        'templateID': value.templateID,
        'fieldKindID': value.fieldKindID,
        'atom': (0, Atom_1.AtomToJSON)(value.atom),
        'message': value.message,
    };
}
exports.ValidationErrorResultToJSON = ValidationErrorResultToJSON;
