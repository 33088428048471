"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateAccountMeritAlternateEmailsRequestToJSON = exports.UpdateAccountMeritAlternateEmailsRequestFromJSONTyped = exports.UpdateAccountMeritAlternateEmailsRequestFromJSON = exports.instanceOfUpdateAccountMeritAlternateEmailsRequest = void 0;
var UpdateAccountMeritAlternateEmailsRequestAlternateEmails_1 = require("./UpdateAccountMeritAlternateEmailsRequestAlternateEmails");
/**
 * Check if a given object implements the UpdateAccountMeritAlternateEmailsRequest interface.
 */
function instanceOfUpdateAccountMeritAlternateEmailsRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "alternateEmails" in value;
    return isInstance;
}
exports.instanceOfUpdateAccountMeritAlternateEmailsRequest = instanceOfUpdateAccountMeritAlternateEmailsRequest;
function UpdateAccountMeritAlternateEmailsRequestFromJSON(json) {
    return UpdateAccountMeritAlternateEmailsRequestFromJSONTyped(json, false);
}
exports.UpdateAccountMeritAlternateEmailsRequestFromJSON = UpdateAccountMeritAlternateEmailsRequestFromJSON;
function UpdateAccountMeritAlternateEmailsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'alternateEmails': (0, UpdateAccountMeritAlternateEmailsRequestAlternateEmails_1.UpdateAccountMeritAlternateEmailsRequestAlternateEmailsFromJSON)(json['alternateEmails']),
    };
}
exports.UpdateAccountMeritAlternateEmailsRequestFromJSONTyped = UpdateAccountMeritAlternateEmailsRequestFromJSONTyped;
function UpdateAccountMeritAlternateEmailsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'alternateEmails': (0, UpdateAccountMeritAlternateEmailsRequestAlternateEmails_1.UpdateAccountMeritAlternateEmailsRequestAlternateEmailsToJSON)(value.alternateEmails),
    };
}
exports.UpdateAccountMeritAlternateEmailsRequestToJSON = UpdateAccountMeritAlternateEmailsRequestToJSON;
