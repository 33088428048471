"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateToJSON = exports.TemplateFromJSONTyped = exports.TemplateFromJSON = exports.instanceOfTemplate = exports.TemplateStateEnum = void 0;
var runtime_1 = require("../runtime");
var EntityInfo_1 = require("./EntityInfo");
var FieldKindPropsValidation_1 = require("./FieldKindPropsValidation");
var PermissionPair_1 = require("./PermissionPair");
var TemplateField_1 = require("./TemplateField");
var TemplatePropsRules_1 = require("./TemplatePropsRules");
var TemplateType_1 = require("./TemplateType");
/**
 * @export
 */
exports.TemplateStateEnum = {
    Paused: 'paused',
    Live: 'live'
};
/**
 * Check if a given object implements the Template interface.
 */
function instanceOfTemplate(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "templateType" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "permissions" in value;
    return isInstance;
}
exports.instanceOfTemplate = instanceOfTemplate;
function TemplateFromJSON(json) {
    return TemplateFromJSONTyped(json, false);
}
exports.TemplateFromJSON = TemplateFromJSON;
function TemplateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'owner': (0, EntityInfo_1.EntityInfoFromJSON)(json['owner']),
        'createdAt': json['createdAt'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'templateType': (0, TemplateType_1.TemplateTypeFromJSON)(json['templateType']),
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : json['parent'],
        'lineage': !(0, runtime_1.exists)(json, 'lineage') ? undefined : json['lineage'],
        'rules': !(0, runtime_1.exists)(json, 'rules') ? undefined : (0, TemplatePropsRules_1.TemplatePropsRulesFromJSON)(json['rules']),
        'state': json['state'],
        'templateFields': !(0, runtime_1.exists)(json, 'templateFields') ? undefined : (json['templateFields'].map(TemplateField_1.TemplateFieldFromJSON)),
        'permissions': (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'activeness': !(0, runtime_1.exists)(json, 'activeness') ? undefined : (0, FieldKindPropsValidation_1.FieldKindPropsValidationFromJSON)(json['activeness']),
        'dataSourceID': !(0, runtime_1.exists)(json, 'dataSourceID') ? undefined : json['dataSourceID'],
        'autoApprove': !(0, runtime_1.exists)(json, 'autoApprove') ? undefined : json['autoApprove'],
        'autoRemove': !(0, runtime_1.exists)(json, 'autoRemove') ? undefined : json['autoRemove'],
        'autoRevoke': !(0, runtime_1.exists)(json, 'autoRevoke') ? undefined : json['autoRevoke'],
        'hasChildren': !(0, runtime_1.exists)(json, 'hasChildren') ? undefined : json['hasChildren'],
    };
}
exports.TemplateFromJSONTyped = TemplateFromJSONTyped;
function TemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'owner': (0, EntityInfo_1.EntityInfoToJSON)(value.owner),
        'createdAt': value.createdAt,
        'name': value.name,
        'description': value.description,
        'templateType': (0, TemplateType_1.TemplateTypeToJSON)(value.templateType),
        'parent': value.parent,
        'lineage': value.lineage,
        'rules': (0, TemplatePropsRules_1.TemplatePropsRulesToJSON)(value.rules),
        'state': value.state,
        'templateFields': value.templateFields === undefined ? undefined : (value.templateFields.map(TemplateField_1.TemplateFieldToJSON)),
        'permissions': (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'activeness': (0, FieldKindPropsValidation_1.FieldKindPropsValidationToJSON)(value.activeness),
        'dataSourceID': value.dataSourceID,
        'autoApprove': value.autoApprove,
        'autoRemove': value.autoRemove,
        'autoRevoke': value.autoRevoke,
        'hasChildren': value.hasChildren,
    };
}
exports.TemplateToJSON = TemplateToJSON;
