"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionToJSON = exports.PermissionFromJSONTyped = exports.PermissionFromJSON = exports.instanceOfPermission = void 0;
var index_1 = require("./index");
/**
 * Check if a given object implements the Permission interface.
 */
function instanceOfPermission(value) {
    var isInstance = true;
    isInstance = isInstance && "grantedTo" in value;
    return isInstance;
}
exports.instanceOfPermission = instanceOfPermission;
function PermissionFromJSON(json) {
    return PermissionFromJSONTyped(json, false);
}
exports.PermissionFromJSON = PermissionFromJSON;
function PermissionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['grantedTo'] === 'All') {
            return (0, index_1.AllFromJSONTyped)(json, true);
        }
        if (json['grantedTo'] === 'None') {
            return (0, index_1.NoneFromJSONTyped)(json, true);
        }
    }
    return {
        'grantedTo': json['grantedTo'],
    };
}
exports.PermissionFromJSONTyped = PermissionFromJSONTyped;
function PermissionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'grantedTo': value.grantedTo,
    };
}
exports.PermissionToJSON = PermissionToJSON;
