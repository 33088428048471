"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchResultsToJSON = exports.SearchResultsFromJSONTyped = exports.SearchResultsFromJSON = exports.instanceOfSearchResults = void 0;
var runtime_1 = require("../runtime");
var Container_1 = require("./Container");
var DataSourceSearchResult_1 = require("./DataSourceSearchResult");
var FieldKindSearchResult_1 = require("./FieldKindSearchResult");
var PolicySearchResult_1 = require("./PolicySearchResult");
var TemplateSearchResult_1 = require("./TemplateSearchResult");
/**
 * Check if a given object implements the SearchResults interface.
 */
function instanceOfSearchResults(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfSearchResults = instanceOfSearchResults;
function SearchResultsFromJSON(json) {
    return SearchResultsFromJSONTyped(json, false);
}
exports.SearchResultsFromJSON = SearchResultsFromJSON;
function SearchResultsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'containers': !(0, runtime_1.exists)(json, 'containers') ? undefined : (json['containers'].map(Container_1.ContainerFromJSON)),
        'templates': !(0, runtime_1.exists)(json, 'templates') ? undefined : (json['templates'].map(TemplateSearchResult_1.TemplateSearchResultFromJSON)),
        'dataSources': !(0, runtime_1.exists)(json, 'dataSources') ? undefined : (json['dataSources'].map(DataSourceSearchResult_1.DataSourceSearchResultFromJSON)),
        'fieldKinds': !(0, runtime_1.exists)(json, 'fieldKinds') ? undefined : (json['fieldKinds'].map(FieldKindSearchResult_1.FieldKindSearchResultFromJSON)),
        'policies': !(0, runtime_1.exists)(json, 'policies') ? undefined : (json['policies'].map(PolicySearchResult_1.PolicySearchResultFromJSON)),
        'hasMore': !(0, runtime_1.exists)(json, 'hasMore') ? undefined : json['hasMore'],
    };
}
exports.SearchResultsFromJSONTyped = SearchResultsFromJSONTyped;
function SearchResultsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'containers': value.containers === undefined ? undefined : (value.containers.map(Container_1.ContainerToJSON)),
        'templates': value.templates === undefined ? undefined : (value.templates.map(TemplateSearchResult_1.TemplateSearchResultToJSON)),
        'dataSources': value.dataSources === undefined ? undefined : (value.dataSources.map(DataSourceSearchResult_1.DataSourceSearchResultToJSON)),
        'fieldKinds': value.fieldKinds === undefined ? undefined : (value.fieldKinds.map(FieldKindSearchResult_1.FieldKindSearchResultToJSON)),
        'policies': value.policies === undefined ? undefined : (value.policies.map(PolicySearchResult_1.PolicySearchResultToJSON)),
        'hasMore': value.hasMore,
    };
}
exports.SearchResultsToJSON = SearchResultsToJSON;
