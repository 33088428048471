"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateAccountMeritAlternateEmailsRequestAlternateEmailsToJSON = exports.UpdateAccountMeritAlternateEmailsRequestAlternateEmailsFromJSONTyped = exports.UpdateAccountMeritAlternateEmailsRequestAlternateEmailsFromJSON = exports.instanceOfUpdateAccountMeritAlternateEmailsRequestAlternateEmails = void 0;
/**
 * Check if a given object implements the UpdateAccountMeritAlternateEmailsRequestAlternateEmails interface.
 */
function instanceOfUpdateAccountMeritAlternateEmailsRequestAlternateEmails(value) {
    var isInstance = true;
    isInstance = isInstance && "primary" in value;
    isInstance = isInstance && "alternates" in value;
    return isInstance;
}
exports.instanceOfUpdateAccountMeritAlternateEmailsRequestAlternateEmails = instanceOfUpdateAccountMeritAlternateEmailsRequestAlternateEmails;
function UpdateAccountMeritAlternateEmailsRequestAlternateEmailsFromJSON(json) {
    return UpdateAccountMeritAlternateEmailsRequestAlternateEmailsFromJSONTyped(json, false);
}
exports.UpdateAccountMeritAlternateEmailsRequestAlternateEmailsFromJSON = UpdateAccountMeritAlternateEmailsRequestAlternateEmailsFromJSON;
function UpdateAccountMeritAlternateEmailsRequestAlternateEmailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'primary': json['primary'],
        'alternates': json['alternates'],
    };
}
exports.UpdateAccountMeritAlternateEmailsRequestAlternateEmailsFromJSONTyped = UpdateAccountMeritAlternateEmailsRequestAlternateEmailsFromJSONTyped;
function UpdateAccountMeritAlternateEmailsRequestAlternateEmailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'primary': value.primary,
        'alternates': value.alternates,
    };
}
exports.UpdateAccountMeritAlternateEmailsRequestAlternateEmailsToJSON = UpdateAccountMeritAlternateEmailsRequestAlternateEmailsToJSON;
