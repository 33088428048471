// Copyright 2022 Merit International Inc. All Rights Reserved

import { None } from "./None";
import { Some } from "./Some";

/*
  Current format for testIds is: {elementId}-{elementName}-{screenName}
*/

export type TestProps = {
  readonly screenName: string;
  readonly elementName: string;
  readonly elementId?: string;
  /*
    androidPrefix is expected to be `${Constants.manifest.android.package}:id/`.
    It will be used to prefix the testID in Android apps.
  */
  readonly androidPrefix?: string;
};

type BaseOptions = {
  readonly componentName?: string;
};
export type HTMLTestIdPropsOptions = BaseOptions & {
  readonly isHTML?: true;
};
export type RNTestIdPropsOptions = BaseOptions & {
  readonly isHTML?: false;
};
export type GenerateTestIdPropsOptions = BaseOptions & {
  readonly isHTML?: boolean;
};
export type RNTestIdProps = Record<string, never> | { readonly testID: string };
export type HTMLTestIdProps = Record<string, never> | { readonly "data-testid": string };

/** @deprecated superseded by {@link RNTestIdProps} */
export type TestIdProps = RNTestIdProps;
/** @deprecated superseded by {@link HTMLTestIdProps} */
export type DivTestIdProps = HTMLTestIdProps;

const generateTestId = ({
  elementId,
  elementName,
  screenName,
}: Omit<TestProps, "androidPrefix">): string => {
  const testId = Some(elementId)
    ? `${elementId}:${elementName}:${screenName}`
    : `${elementName}:${screenName}`;

  /*
    testID should not contain whitespaces, so those replaced with underscores.
  */
  const whitespaces = [" ", "\u00a0", "\n", "\r"];

  return whitespaces.reduce((str, char) => str.replaceAll(char, "_"), testId);
};

export type GenerateTestIdPropsTypeForOptions<T extends GenerateTestIdPropsOptions> =
  T["isHTML"] extends true ? HTMLTestIdProps : RNTestIdProps;

/**
  This can be used to add data-testids for Mobile(iOS,Android), Mobile web, Desktop web
  testID can be combination of {elementId}-{elementName}-{screenName} / {elementName}-{screenName}
  `androidPrefix` is expected to be `${Constants.manifest.android.package}:id/`, see also PE-525.
  Overloads needed for return type narrowing based on `options.isHTML`'s value.
  More reading from SO: {@link https://stackoverflow.com/a/52818072}
  */
export function generateTestIdProps<T extends GenerateTestIdPropsOptions>(
  testProps?: TestProps,
  options?: T
): GenerateTestIdPropsTypeForOptions<T>;
export function generateTestIdProps<T extends GenerateTestIdPropsOptions>(
  testProps?: TestProps,
  options?: T
): HTMLTestIdProps | RNTestIdProps {
  if (None(testProps)) {
    /*
      We return empty object which will add nothing to the component when spread.
      This is to avoid checking for undefined testProps in the component.
    */
    return {};
  }

  const { androidPrefix } = testProps;
  const { componentName = "", isHTML = false } = options ?? {};
  const formattedTestId = generateTestId({
    ...testProps,
    elementName: `${testProps.elementName}${componentName}`,
  });

  if (Some(androidPrefix)) {
    return {
      testID: `${androidPrefix}${formattedTestId}`,
    };
  }

  if (isHTML) {
    return {
      "data-testid": formattedTestId,
    };
  }

  return {
    testID: formattedTestId,
  };
}

/** @deprecated superseded by {@link TestProps} */
type TestPropInput = {
  readonly componentName: string;
  readonly testID?: string;
  readonly uniqueID?: string;
};

/** @deprecated superseded by {@link generateTestIdProps} */
export function generateTestProps({ componentName, testID, uniqueID }: TestPropInput): TestIdProps {
  return testID === undefined
    ? { testID: componentName }
    : generateTestIdProps(
        {
          elementId: uniqueID,
          elementName: testID,
          screenName: componentName,
        },
        {
          isHTML: false,
        }
      );
}

/** @deprecated superseded by {@link generateTestIdProps} */
export function generateDivTestProps({
  componentName,
  testID,
  uniqueID,
}: TestPropInput): DivTestIdProps {
  return testID === undefined
    ? { "data-testid": componentName }
    : generateTestIdProps(
        {
          elementId: uniqueID,
          elementName: testID,
          screenName: componentName,
        },
        {
          isHTML: true,
        }
      );
}
