"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormulaToJSON = exports.FormulaFromJSONTyped = exports.FormulaFromJSON = exports.instanceOfFormula = exports.FormulaFormulaTypeEnum = void 0;
var index_1 = require("./index");
/**
 * @export
 */
exports.FormulaFormulaTypeEnum = {
    Conjunction: 'Conjunction',
    Disjunction: 'Disjunction',
    CompoundAtom: 'CompoundAtom'
};
/**
 * Check if a given object implements the Formula interface.
 */
function instanceOfFormula(value) {
    var isInstance = true;
    isInstance = isInstance && "formulaType" in value;
    return isInstance;
}
exports.instanceOfFormula = instanceOfFormula;
function FormulaFromJSON(json) {
    return FormulaFromJSONTyped(json, false);
}
exports.FormulaFromJSON = FormulaFromJSON;
function FormulaFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['formulaType'] === 'CompoundAtom') {
            return (0, index_1.CompoundAtomFromJSONTyped)(json, true);
        }
        if (json['formulaType'] === 'Conjunction') {
            return (0, index_1.ConjunctionFromJSONTyped)(json, true);
        }
        if (json['formulaType'] === 'Disjunction') {
            return (0, index_1.DisjunctionFromJSONTyped)(json, true);
        }
    }
    return {
        'formulaType': json['formulaType'],
    };
}
exports.FormulaFromJSONTyped = FormulaFromJSONTyped;
function FormulaToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'formulaType': value.formulaType,
    };
}
exports.FormulaToJSON = FormulaToJSON;
