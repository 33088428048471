"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportedCredentialToJSON = exports.ExportedCredentialFromJSONTyped = exports.ExportedCredentialFromJSON = exports.instanceOfExportedCredential = void 0;
/**
 * Check if a given object implements the ExportedCredential interface.
 */
function instanceOfExportedCredential(value) {
    var isInstance = true;
    isInstance = isInstance && "credentialId" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;
    return isInstance;
}
exports.instanceOfExportedCredential = instanceOfExportedCredential;
function ExportedCredentialFromJSON(json) {
    return ExportedCredentialFromJSONTyped(json, false);
}
exports.ExportedCredentialFromJSON = ExportedCredentialFromJSON;
function ExportedCredentialFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'credentialId': json['credentialId'],
        'name': json['name'],
        'url': json['url'],
    };
}
exports.ExportedCredentialFromJSONTyped = ExportedCredentialFromJSONTyped;
function ExportedCredentialToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'credentialId': value.credentialId,
        'name': value.name,
        'url': value.url,
    };
}
exports.ExportedCredentialToJSON = ExportedCredentialToJSON;
