// Copyright 2024 Merit International Inc. All Rights Reserved

import { Body } from "../Text/Body/Body";
import { Dropdown } from "react-native-element-dropdown";
import { Icon } from "../Icon";
import { StyleSheet } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useMemo, useState } from "react";
import { useTheme } from "../../theme/useTheme";
import type { ComponentTestProps } from "../../utils/testProps";
import type { DropdownProps } from "react-native-element-dropdown/lib/typescript/components/Dropdown/model";
import type { ViewStyle } from "react-native";

// for the sake of simplicity, use ISO-639-1 2-letter language codes for now
// special case for "fil" (Filipino), which according to CLDR is preferred over "tl" (Tagalog)
// ideally we'll support IETF BCP 47 which includes 3-letter codes, regional variants, etc
type SupportedLanguageCode =
  | "ar"
  | "de"
  | "en"
  | "es"
  | "fil"
  | "fr"
  | "ht"
  | "ko"
  | "ru"
  | "vi"
  | "zh";
export type LanguageDetails = {
  readonly languageCode: SupportedLanguageCode;
  readonly englishDisplayName: string;
  readonly nativeDisplayName: string;
};
/* eslint-disable sort-keys-fix/sort-keys-fix */
const SUPPORTED_LANGUAGES: Record<SupportedLanguageCode, LanguageDetails> = {
  ar: {
    englishDisplayName: "Arabic (Modern Standard)",
    languageCode: "ar",
    nativeDisplayName: "العربية الفصحى",
  },
  de: { englishDisplayName: "German", languageCode: "de", nativeDisplayName: "Deutsch" },
  en: { englishDisplayName: "English", languageCode: "en", nativeDisplayName: "English" },
  es: { englishDisplayName: "Spanish", languageCode: "es", nativeDisplayName: "Español" },
  fr: { englishDisplayName: "French", languageCode: "fr", nativeDisplayName: "Français" },
  ht: {
    englishDisplayName: "Haitian Creole",
    languageCode: "ht",
    nativeDisplayName: "Kreyòl Ayisyen",
  },
  ko: { englishDisplayName: "Korean", languageCode: "ko", nativeDisplayName: "한국어" },
  ru: { englishDisplayName: "Russian", languageCode: "ru", nativeDisplayName: "Pусский" },
  // for most intents and purposes, Tagalog and Filipino are interchangeable
  fil: { englishDisplayName: "Tagalog", languageCode: "fil", nativeDisplayName: "Tagalog" },
  vi: { englishDisplayName: "Vietnamese", languageCode: "vi", nativeDisplayName: "Tiếng Việt" },
  zh: {
    englishDisplayName: "Chinese (Simplified)",
    languageCode: "zh",
    nativeDisplayName: "简体字",
  },
} as const;
/* eslint-enable */

export type LanguageSelectProps = ComponentTestProps & {
  readonly defaultValue?: DropdownProps<LanguageDetails>["value"];
  readonly languages?: readonly SupportedLanguageCode[];
  readonly onBlur?: DropdownProps<LanguageDetails>["onBlur"];
  readonly onChange: DropdownProps<LanguageDetails>["onChange"];
  readonly onFocus?: DropdownProps<LanguageDetails>["onFocus"];
};

const FONT_FAMILY = "ProximaNova";
const HEIGHT = 32;

export const LanguageSelect = ({
  defaultValue,
  languages,
  onBlur,
  onChange,
  onFocus,
  testProps,
}: LanguageSelectProps) => {
  const { theme } = useTheme();
  const styles = StyleSheet.create<{
    readonly dropdownContainer: ViewStyle;
    readonly item: ViewStyle;
    readonly placeholder: ViewStyle;
    readonly selectContainer: ViewStyle;
    readonly selectedText: ViewStyle;
  }>({
    dropdownContainer: {
      borderColor: theme.colors.border.default,
      borderRadius: theme.borderRadii.s,
      borderWidth: 1,
    },
    item: {
      paddingHorizontal: theme.spacing.l,
      paddingVertical: theme.spacing.s,
    },
    placeholder: {
      color: theme.colors.text.subdued,
      // fontSize and lineHeight for <Body />
      fontSize: 14,
      lineHeight: 20,
      paddingHorizontal: theme.spacing.xs,
    },
    selectContainer: {
      backgroundColor: theme.colors.background.white,
      borderColor: theme.colors.border.default,
      borderRadius: HEIGHT / 2,
      borderWidth: 1,
      height: HEIGHT,
      padding: theme.spacing.s,
    },
    selectedText: {
      // fontSize and lineHeight for <Body />
      fontSize: 14,
      lineHeight: 20,
      paddingHorizontal: theme.spacing.xs,
    },
  });

  const [isOpen, setIsOpen] = useState(false);

  // eslint-disable-next-line functional/prefer-readonly-type
  const languageOptions = useMemo<LanguageDetails[]>(() => {
    if (languages === undefined) {
      return Object.values(SUPPORTED_LANGUAGES);
    }

    return languages.map(languageCode => SUPPORTED_LANGUAGES[languageCode]);
  }, [languages]);

  return (
    <Dropdown<LanguageDetails>
      activeColor={theme.colors.brand.turquiose}
      containerStyle={styles.dropdownContainer}
      data={languageOptions}
      fontFamily={FONT_FAMILY}
      labelField="nativeDisplayName"
      mode="modal"
      onBlur={() => {
        setIsOpen(false);
        onBlur?.();
      }}
      onChange={onChange}
      onFocus={() => {
        setIsOpen(true);
        onFocus?.();
      }}
      placeholderStyle={styles.placeholder}
      renderItem={item => (
        <Body
          style={styles.item}
          testProps={{
            elementId: item.languageCode,
            elementName: "LanguageSelectOption",
            screenName: testProps?.screenName ?? "",
          }}
        >
          {item.nativeDisplayName}
        </Body>
      )}
      renderLeftIcon={() => <Icon name="globeMediumSubdued" />}
      renderRightIcon={() =>
        isOpen ? <Icon name="chevronUpSmallDefault" /> : <Icon name="chevronDownSmallDefault" />
      }
      selectedTextStyle={styles.selectedText}
      style={styles.selectContainer}
      value={defaultValue}
      valueField="languageCode"
      {...getTestProps({ testProps })}
    />
  );
};
