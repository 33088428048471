"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyRequestPatchToJSON = exports.PolicyRequestPatchFromJSONTyped = exports.PolicyRequestPatchFromJSON = exports.instanceOfPolicyRequestPatch = exports.PolicyRequestPatchStatusEnum = void 0;
var runtime_1 = require("../runtime");
/**
 * @export
 */
exports.PolicyRequestPatchStatusEnum = {
    Approved: 'Approved',
    Completed: 'Completed',
    Denied: 'Denied',
    Requested: 'Requested'
};
/**
 * Check if a given object implements the PolicyRequestPatch interface.
 */
function instanceOfPolicyRequestPatch(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPolicyRequestPatch = instanceOfPolicyRequestPatch;
function PolicyRequestPatchFromJSON(json) {
    return PolicyRequestPatchFromJSONTyped(json, false);
}
exports.PolicyRequestPatchFromJSON = PolicyRequestPatchFromJSON;
function PolicyRequestPatchFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : json['status'],
    };
}
exports.PolicyRequestPatchFromJSONTyped = PolicyRequestPatchFromJSONTyped;
function PolicyRequestPatchToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': value.status,
    };
}
exports.PolicyRequestPatchToJSON = PolicyRequestPatchToJSON;
