"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityInfoToJSON = exports.EntityInfoFromJSONTyped = exports.EntityInfoFromJSON = exports.instanceOfEntityInfo = exports.EntityInfoStateEnum = exports.EntityInfoTypeEnum = void 0;
/**
 * @export
 */
exports.EntityInfoTypeEnum = {
    Organization: 'organization',
    Person: 'person'
};
/**
 * @export
 */
exports.EntityInfoStateEnum = {
    None: 'none',
    Invited: 'invited',
    Active: 'active',
    Rejected: 'rejected',
    Removed: 'removed'
};
/**
 * Check if a given object implements the EntityInfo interface.
 */
function instanceOfEntityInfo(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "state" in value;
    return isInstance;
}
exports.instanceOfEntityInfo = instanceOfEntityInfo;
function EntityInfoFromJSON(json) {
    return EntityInfoFromJSONTyped(json, false);
}
exports.EntityInfoFromJSON = EntityInfoFromJSON;
function EntityInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'type': json['type'],
        'state': json['state'],
    };
}
exports.EntityInfoFromJSONTyped = EntityInfoFromJSONTyped;
function EntityInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'type': value.type,
        'state': value.state,
    };
}
exports.EntityInfoToJSON = EntityInfoToJSON;
