// Copyright 2022 Merit International Inc. All Rights Reserved

import { Body } from "../Text";
import { Helpers } from "@merit/frontend-utils";
import { Image, Pressable, StyleSheet, View } from "react-native";
import { getTestProps } from "../../utils/testProps";
import { useActive, useFocus, useHover } from "react-native-web-hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTheme } from "../../theme/useTheme";
import type { ComponentTestProps } from "../../utils/testProps";
import type { ReactNode } from "react";

const { Some, getPseudoState } = Helpers;

export type CheckboxProps = ComponentTestProps & {
  readonly accessibilityHint?: string;
  readonly accessibilityLabel?: string; // Overrides testId generation
  readonly defaultChecked?: boolean;
  readonly disabled?: boolean;
  readonly label?: ReactNode;
  readonly labelSize?: "medium" | "small";
  readonly onChange: (checked: boolean) => void;
  readonly size?: "medium" | "small";
  readonly type?: "checkmark" | "unstated";
};

export const Checkbox = ({
  accessibilityHint,
  accessibilityLabel,
  defaultChecked,
  disabled = false,
  label,
  labelSize = "small",
  onChange,
  size = "small",
  testID,
  testProps,
  testUniqueID,
  type = "checkmark",
}: CheckboxProps) => {
  const [isChecked, setIsChecked] = useState(defaultChecked ?? false);
  const { theme } = useTheme();

  useEffect(() => {
    setIsChecked(defaultChecked ?? false);
  }, [defaultChecked]);

  const ref = useRef(null);
  const isHovered = useHover(ref);
  const isFocused = useFocus(ref);
  const isActive = useActive(ref);
  const checkboxState = useMemo(
    () => getPseudoState(disabled, isActive, isHovered, isFocused),
    [isHovered, isFocused, isActive, disabled]
  );

  const styles = StyleSheet.create({
    checkboxBase: {
      alignItems: "center",
      borderRadius: 2,
      borderWidth: 1,
      height: size === "small" ? 16 : 20,
      justifyContent: "center",
      marginRight: Some(label) ? 6 : undefined,
      width: size === "small" ? 16 : 20,
    },
    checkboxChecked: {
      backgroundColor: theme.colors.action.default,
      borderColor: theme.colors.action.default,
    },
    checkboxContainer: {
      justifyContent: "center",
    },
    checkboxLabel: {
      color: theme.colors.text.default,
      flex: 1,
    },
    container: {
      alignItems: "stretch",
      flexDirection: "row",
    },
  });

  const calculatedStyles = useMemo(() => {
    switch (checkboxState) {
      case "disabled": {
        return {
          checkbox: {
            borderColor: theme.colors.border.default,
            opacity: 0.4,
          },
        };
      }

      case "active": {
        return {
          checkbox: {
            backgroundColor: theme.colors.action.pressed,
            borderColor: theme.colors.border.action.pressed,
          },
        };
      }

      case "hovered": {
        return {
          checkbox: {
            backgroundColor: "transparent",
            borderColor: theme.colors.action.hovered,
          },
        };
      }

      case "focused": {
        return {
          checkbox: {
            backgroundColor: theme.colors.action.disabled,
            borderColor: theme.colors.border.action.default,
          },
        };
      }

      case "none": {
        return {
          checkbox: {
            backgroundColor: "transparent",
            borderColor: theme.colors.border.default,
          },
        };
      }

      default:
        throw new Error("Invalid Checkbox State");
    }
  }, [theme, checkboxState]);

  return (
    <Pressable
      accessibilityHint={accessibilityHint}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole="checkbox"
      disabled={disabled}
      onPress={() => {
        setIsChecked(prevState => !prevState);
        onChange(!isChecked);
      }}
      ref={ref}
    >
      <View style={styles.container}>
        <View style={styles.checkboxContainer}>
          <View
            style={[
              styles.checkboxBase,
              calculatedStyles.checkbox,
              isChecked && styles.checkboxChecked,
            ]}
            {...getTestProps(
              { componentName: "Checkbox", testID, testProps, testUniqueID },
              { componentName: "Checkbox" }
            )}
          >
            {isChecked && (
              <Image
                source={
                  type === "checkmark"
                    ? theme.icons.checkmarkSmallAction
                    : theme.icons.removeSmallAction
                }
                style={{
                  height: size === "small" ? 16 : 20,
                  width: size === "small" ? 16 : 20,
                }}
              />
            )}
          </View>
        </View>
        {typeof label === "string" || typeof label === "number" ? (
          <Body
            size={labelSize === "small" ? "s" : "l"}
            style={styles.checkboxLabel}
            {...getTestProps(
              { componentName: "CheckboxLabel", testID, testProps, testUniqueID },
              { componentName: "CheckboxLabel" }
            )}
          >
            {label}
          </Body>
        ) : (
          label
        )}
      </View>
    </Pressable>
  );
};
