"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyRequestsResponseToJSON = exports.PolicyRequestsResponseFromJSONTyped = exports.PolicyRequestsResponseFromJSON = exports.instanceOfPolicyRequestsResponse = void 0;
var runtime_1 = require("../runtime");
var PaginationResponseCursor_1 = require("./PaginationResponseCursor");
var PolicyRequest_1 = require("./PolicyRequest");
/**
 * Check if a given object implements the PolicyRequestsResponse interface.
 */
function instanceOfPolicyRequestsResponse(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPolicyRequestsResponse = instanceOfPolicyRequestsResponse;
function PolicyRequestsResponseFromJSON(json) {
    return PolicyRequestsResponseFromJSONTyped(json, false);
}
exports.PolicyRequestsResponseFromJSON = PolicyRequestsResponseFromJSON;
function PolicyRequestsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'policyRequests': !(0, runtime_1.exists)(json, 'policyRequests') ? undefined : (json['policyRequests'].map(PolicyRequest_1.PolicyRequestFromJSON)),
        'hasMore': !(0, runtime_1.exists)(json, 'hasMore') ? undefined : json['hasMore'],
        'cursor': !(0, runtime_1.exists)(json, 'cursor') ? undefined : (0, PaginationResponseCursor_1.PaginationResponseCursorFromJSON)(json['cursor']),
    };
}
exports.PolicyRequestsResponseFromJSONTyped = PolicyRequestsResponseFromJSONTyped;
function PolicyRequestsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'policyRequests': value.policyRequests === undefined ? undefined : (value.policyRequests.map(PolicyRequest_1.PolicyRequestToJSON)),
        'hasMore': value.hasMore,
        'cursor': (0, PaginationResponseCursor_1.PaginationResponseCursorToJSON)(value.cursor),
    };
}
exports.PolicyRequestsResponseToJSON = PolicyRequestsResponseToJSON;
