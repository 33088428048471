import Markdown from "./lib/Markdown";
import Renderer from "./lib/Renderer";
import type {
	MarkdownProps,
	ParserOptions,
	RendererInterface,
} from "./lib/types";

export type { MarkdownProps, ParserOptions, RendererInterface };
export { Renderer };
export default Markdown;
