"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldKindToJSON = exports.FieldKindFromJSONTyped = exports.FieldKindFromJSON = exports.instanceOfFieldKind = void 0;
var runtime_1 = require("../runtime");
var EntityInfo_1 = require("./EntityInfo");
var FieldKindPropsValidation_1 = require("./FieldKindPropsValidation");
var FieldType_1 = require("./FieldType");
var PermissionPair_1 = require("./PermissionPair");
/**
 * Check if a given object implements the FieldKind interface.
 */
function instanceOfFieldKind(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "dataType" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "permissions" in value;
    return isInstance;
}
exports.instanceOfFieldKind = instanceOfFieldKind;
function FieldKindFromJSON(json) {
    return FieldKindFromJSONTyped(json, false);
}
exports.FieldKindFromJSON = FieldKindFromJSON;
function FieldKindFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'owner': (0, EntityInfo_1.EntityInfoFromJSON)(json['owner']),
        'createdAt': json['createdAt'],
        'dataType': (0, FieldType_1.FieldTypeFromJSON)(json['dataType']),
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : json['parent'],
        'validation': !(0, runtime_1.exists)(json, 'validation') ? undefined : (0, FieldKindPropsValidation_1.FieldKindPropsValidationFromJSON)(json['validation']),
        'permissions': (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'hasChildren': !(0, runtime_1.exists)(json, 'hasChildren') ? undefined : json['hasChildren'],
    };
}
exports.FieldKindFromJSONTyped = FieldKindFromJSONTyped;
function FieldKindToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'owner': (0, EntityInfo_1.EntityInfoToJSON)(value.owner),
        'createdAt': value.createdAt,
        'dataType': (0, FieldType_1.FieldTypeToJSON)(value.dataType),
        'name': value.name,
        'description': value.description,
        'parent': value.parent,
        'validation': (0, FieldKindPropsValidation_1.FieldKindPropsValidationToJSON)(value.validation),
        'permissions': (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'hasChildren': value.hasChildren,
    };
}
exports.FieldKindToJSON = FieldKindToJSON;
