"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContainerFieldQueryToJSON = exports.ContainerFieldQueryFromJSONTyped = exports.ContainerFieldQueryFromJSON = exports.instanceOfContainerFieldQuery = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ContainerFieldQuery interface.
 */
function instanceOfContainerFieldQuery(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfContainerFieldQuery = instanceOfContainerFieldQuery;
function ContainerFieldQueryFromJSON(json) {
    return ContainerFieldQueryFromJSONTyped(json, false);
}
exports.ContainerFieldQueryFromJSON = ContainerFieldQueryFromJSON;
function ContainerFieldQueryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'templateFieldID': !(0, runtime_1.exists)(json, 'templateFieldID') ? undefined : json['templateFieldID'],
        'baseTemplateFieldID': !(0, runtime_1.exists)(json, 'baseTemplateFieldID') ? undefined : json['baseTemplateFieldID'],
        'query': !(0, runtime_1.exists)(json, 'query') ? undefined : json['query'],
        'exactMatch': !(0, runtime_1.exists)(json, 'exactMatch') ? undefined : json['exactMatch'],
    };
}
exports.ContainerFieldQueryFromJSONTyped = ContainerFieldQueryFromJSONTyped;
function ContainerFieldQueryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'templateFieldID': value.templateFieldID,
        'baseTemplateFieldID': value.baseTemplateFieldID,
        'query': value.query,
        'exactMatch': value.exactMatch,
    };
}
exports.ContainerFieldQueryToJSON = ContainerFieldQueryToJSON;
