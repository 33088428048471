"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisjunctionToJSON = exports.DisjunctionFromJSONTyped = exports.DisjunctionFromJSON = exports.instanceOfDisjunction = void 0;
var Formula_1 = require("./Formula");
/**
 * Check if a given object implements the Disjunction interface.
 */
function instanceOfDisjunction(value) {
    var isInstance = true;
    isInstance = isInstance && "disjunction" in value;
    return isInstance;
}
exports.instanceOfDisjunction = instanceOfDisjunction;
function DisjunctionFromJSON(json) {
    return DisjunctionFromJSONTyped(json, false);
}
exports.DisjunctionFromJSON = DisjunctionFromJSON;
function DisjunctionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, Formula_1.FormulaFromJSONTyped)(json, ignoreDiscriminator)), { 'disjunction': (json['disjunction'].map(Formula_1.FormulaFromJSON)) });
}
exports.DisjunctionFromJSONTyped = DisjunctionFromJSONTyped;
function DisjunctionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, Formula_1.FormulaToJSON)(value)), { 'disjunction': (value.disjunction.map(Formula_1.FormulaToJSON)) });
}
exports.DisjunctionToJSON = DisjunctionToJSON;
