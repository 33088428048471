"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateFieldColumnMappingPatchToJSON = exports.TemplateFieldColumnMappingPatchFromJSONTyped = exports.TemplateFieldColumnMappingPatchFromJSON = exports.instanceOfTemplateFieldColumnMappingPatch = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the TemplateFieldColumnMappingPatch interface.
 */
function instanceOfTemplateFieldColumnMappingPatch(value) {
    var isInstance = true;
    isInstance = isInstance && "templateFieldID" in value;
    return isInstance;
}
exports.instanceOfTemplateFieldColumnMappingPatch = instanceOfTemplateFieldColumnMappingPatch;
function TemplateFieldColumnMappingPatchFromJSON(json) {
    return TemplateFieldColumnMappingPatchFromJSONTyped(json, false);
}
exports.TemplateFieldColumnMappingPatchFromJSON = TemplateFieldColumnMappingPatchFromJSON;
function TemplateFieldColumnMappingPatchFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'templateFieldID': json['templateFieldID'],
        'columnID': !(0, runtime_1.exists)(json, 'columnID') ? undefined : json['columnID'],
        'containerID': !(0, runtime_1.exists)(json, 'containerID') ? undefined : json['containerID'],
        'containerFieldID': !(0, runtime_1.exists)(json, 'containerFieldID') ? undefined : json['containerFieldID'],
    };
}
exports.TemplateFieldColumnMappingPatchFromJSONTyped = TemplateFieldColumnMappingPatchFromJSONTyped;
function TemplateFieldColumnMappingPatchToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'templateFieldID': value.templateFieldID,
        'columnID': value.columnID,
        'containerID': value.containerID,
        'containerFieldID': value.containerFieldID,
    };
}
exports.TemplateFieldColumnMappingPatchToJSON = TemplateFieldColumnMappingPatchToJSON;
