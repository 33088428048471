"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompoundAtomToJSON = exports.CompoundAtomFromJSONTyped = exports.CompoundAtomFromJSON = exports.instanceOfCompoundAtom = exports.CompoundAtomPredicateEnum = void 0;
var runtime_1 = require("../runtime");
var Formula_1 = require("./Formula");
/**
 * @export
 */
exports.CompoundAtomPredicateEnum = {
    AfterNow: 'AfterNow',
    AfterThisDate: 'AfterThisDate',
    AfterThisDatetime: 'AfterThisDatetime',
    AfterThisTimeOfDay: 'AfterThisTimeOfDay',
    AfterToday: 'AfterToday',
    BeforeNow: 'BeforeNow',
    BeforeNowMinusXDuration: 'BeforeNowMinusXDuration',
    BeforeThisDate: 'BeforeThisDate',
    BeforeThisDatetime: 'BeforeThisDatetime',
    BeforeThisTimeOfDay: 'BeforeThisTimeOfDay',
    BeforeToday: 'BeforeToday',
    BeforeTodayMinusXDays: 'BeforeTodayMinusXDays',
    EqualToDate: 'EqualToDate',
    EqualToX: 'EqualToX',
    FieldHasValue: 'FieldHasValue',
    IsEmailDomain: 'IsEmailDomain',
    IsFalse: 'IsFalse',
    IsTrue: 'IsTrue',
    LessThanX: 'LessThanX',
    MatchesThisString: 'MatchesThisString',
    MoreThanX: 'MoreThanX',
    MappableTo: 'MappableTo',
    ReceivedXContainersFromTemplates: 'ReceivedXContainersFromTemplates',
    ReceivedContainersFromXTemplates: 'ReceivedContainersFromXTemplates'
};
/**
 * Check if a given object implements the CompoundAtom interface.
 */
function instanceOfCompoundAtom(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCompoundAtom = instanceOfCompoundAtom;
function CompoundAtomFromJSON(json) {
    return CompoundAtomFromJSONTyped(json, false);
}
exports.CompoundAtomFromJSON = CompoundAtomFromJSON;
function CompoundAtomFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, Formula_1.FormulaFromJSONTyped)(json, ignoreDiscriminator)), { 'target': !(0, runtime_1.exists)(json, 'target') ? undefined : json['target'], 'predicate': !(0, runtime_1.exists)(json, 'predicate') ? undefined : json['predicate'], 'arguments': !(0, runtime_1.exists)(json, 'arguments') ? undefined : json['arguments'], 'errorMessage': !(0, runtime_1.exists)(json, 'errorMessage') ? undefined : json['errorMessage'], 'formula': !(0, runtime_1.exists)(json, 'formula') ? undefined : (0, Formula_1.FormulaFromJSON)(json['formula']) });
}
exports.CompoundAtomFromJSONTyped = CompoundAtomFromJSONTyped;
function CompoundAtomToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, Formula_1.FormulaToJSON)(value)), { 'target': value.target, 'predicate': value.predicate, 'arguments': value.arguments, 'errorMessage': value.errorMessage, 'formula': (0, Formula_1.FormulaToJSON)(value.formula) });
}
exports.CompoundAtomToJSON = CompoundAtomToJSON;
