"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OwnerMetadataToJSON = exports.OwnerMetadataFromJSONTyped = exports.OwnerMetadataFromJSON = exports.instanceOfOwnerMetadata = void 0;
var EntityInfo_1 = require("./EntityInfo");
/**
 * Check if a given object implements the OwnerMetadata interface.
 */
function instanceOfOwnerMetadata(value) {
    var isInstance = true;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "createdAt" in value;
    return isInstance;
}
exports.instanceOfOwnerMetadata = instanceOfOwnerMetadata;
function OwnerMetadataFromJSON(json) {
    return OwnerMetadataFromJSONTyped(json, false);
}
exports.OwnerMetadataFromJSON = OwnerMetadataFromJSON;
function OwnerMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'owner': (0, EntityInfo_1.EntityInfoFromJSON)(json['owner']),
        'createdAt': json['createdAt'],
    };
}
exports.OwnerMetadataFromJSONTyped = OwnerMetadataFromJSONTyped;
function OwnerMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'owner': (0, EntityInfo_1.EntityInfoToJSON)(value.owner),
        'createdAt': value.createdAt,
    };
}
exports.OwnerMetadataToJSON = OwnerMetadataToJSON;
