"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateMFAEnrollmentTicketResponseToJSON = exports.CreateMFAEnrollmentTicketResponseFromJSONTyped = exports.CreateMFAEnrollmentTicketResponseFromJSON = exports.instanceOfCreateMFAEnrollmentTicketResponse = void 0;
/**
 * Check if a given object implements the CreateMFAEnrollmentTicketResponse interface.
 */
function instanceOfCreateMFAEnrollmentTicketResponse(value) {
    var isInstance = true;
    isInstance = isInstance && "url" in value;
    return isInstance;
}
exports.instanceOfCreateMFAEnrollmentTicketResponse = instanceOfCreateMFAEnrollmentTicketResponse;
function CreateMFAEnrollmentTicketResponseFromJSON(json) {
    return CreateMFAEnrollmentTicketResponseFromJSONTyped(json, false);
}
exports.CreateMFAEnrollmentTicketResponseFromJSON = CreateMFAEnrollmentTicketResponseFromJSON;
function CreateMFAEnrollmentTicketResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': json['url'],
    };
}
exports.CreateMFAEnrollmentTicketResponseFromJSONTyped = CreateMFAEnrollmentTicketResponseFromJSONTyped;
function CreateMFAEnrollmentTicketResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
    };
}
exports.CreateMFAEnrollmentTicketResponseToJSON = CreateMFAEnrollmentTicketResponseToJSON;
