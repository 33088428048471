"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContainerFieldToJSON = exports.ContainerFieldFromJSONTyped = exports.ContainerFieldFromJSON = exports.instanceOfContainerField = void 0;
var runtime_1 = require("../runtime");
var FieldKindInfo_1 = require("./FieldKindInfo");
var ValidationErrorResult_1 = require("./ValidationErrorResult");
/**
 * Check if a given object implements the ContainerField interface.
 */
function instanceOfContainerField(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfContainerField = instanceOfContainerField;
function ContainerFieldFromJSON(json) {
    return ContainerFieldFromJSONTyped(json, false);
}
exports.ContainerFieldFromJSON = ContainerFieldFromJSON;
function ContainerFieldFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'value': !(0, runtime_1.exists)(json, 'value') ? undefined : json['value'],
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'templateFieldID': !(0, runtime_1.exists)(json, 'templateFieldID') ? undefined : json['templateFieldID'],
        'fieldKind': !(0, runtime_1.exists)(json, 'fieldKind') ? undefined : (0, FieldKindInfo_1.FieldKindInfoFromJSON)(json['fieldKind']),
        'baseTemplateFieldID': !(0, runtime_1.exists)(json, 'baseTemplateFieldID') ? undefined : json['baseTemplateFieldID'],
        'canShare': !(0, runtime_1.exists)(json, 'canShare') ? undefined : json['canShare'],
        'validationErrors': !(0, runtime_1.exists)(json, 'validationErrors') ? undefined : (json['validationErrors'].map(ValidationErrorResult_1.ValidationErrorResultFromJSON)),
    };
}
exports.ContainerFieldFromJSONTyped = ContainerFieldFromJSONTyped;
function ContainerFieldToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'value': value.value,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'templateFieldID': value.templateFieldID,
        'fieldKind': (0, FieldKindInfo_1.FieldKindInfoToJSON)(value.fieldKind),
        'baseTemplateFieldID': value.baseTemplateFieldID,
        'canShare': value.canShare,
        'validationErrors': value.validationErrors === undefined ? undefined : (value.validationErrors.map(ValidationErrorResult_1.ValidationErrorResultToJSON)),
    };
}
exports.ContainerFieldToJSON = ContainerFieldToJSON;
