// this import needed to address error TS2742 that recently started showing up
import type React from "react";

// This function is used to merge ForwardRefExoticComponent's ref and MutableRefObject
export const mergeRefs =
  <T = any>( // eslint-disable-line @typescript-eslint/no-explicit-any
    refs: readonly (React.LegacyRef<T> | React.MutableRefObject<T>)[]
  ): React.RefCallback<T> =>
  value => {
    refs.forEach(ref => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref !== null) {
        /* eslint-disable-next-line functional/immutable-data, no-param-reassign */
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
