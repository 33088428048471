"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContainerStateToJSON = exports.ContainerStateFromJSONTyped = exports.ContainerStateFromJSON = exports.instanceOfContainerState = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ContainerState interface.
 */
function instanceOfContainerState(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfContainerState = instanceOfContainerState;
function ContainerStateFromJSON(json) {
    return ContainerStateFromJSONTyped(json, false);
}
exports.ContainerStateFromJSON = ContainerStateFromJSON;
function ContainerStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'occurredAt': !(0, runtime_1.exists)(json, 'occurredAt') ? undefined : (json['occurredAt'] === null ? null : new Date(json['occurredAt'])),
    };
}
exports.ContainerStateFromJSONTyped = ContainerStateFromJSONTyped;
function ContainerStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'occurredAt': value.occurredAt === undefined ? undefined : (value.occurredAt === null ? null : value.occurredAt.toISOString()),
    };
}
exports.ContainerStateToJSON = ContainerStateToJSON;
