// Copyright 2022 Merit International Inc. All Rights Reserved
// migrated from merit-frontend/src/util/getPseudoState.ts

export type PseudoState = "active" | "disabled" | "focused" | "hovered" | "none";

export const getPseudoState = (
  disabled: boolean,
  active: boolean,
  hovered: boolean,
  focused: boolean
): PseudoState => {
  // State hierarchy is disabled > active > hovered > focused
  if (disabled) {
    return "disabled";
  }
  if (active) {
    return "active";
  }
  if (hovered) {
    return "hovered";
  }
  if (focused) {
    return "focused";
  }

  return "none";
};
