"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseFormulasToJSON = exports.ResponseFormulasFromJSONTyped = exports.ResponseFormulasFromJSON = exports.instanceOfResponseFormulas = void 0;
var runtime_1 = require("../runtime");
var Formula_1 = require("./Formula");
/**
 * Check if a given object implements the ResponseFormulas interface.
 */
function instanceOfResponseFormulas(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfResponseFormulas = instanceOfResponseFormulas;
function ResponseFormulasFromJSON(json) {
    return ResponseFormulasFromJSONTyped(json, false);
}
exports.ResponseFormulasFromJSON = ResponseFormulasFromJSON;
function ResponseFormulasFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'own': !(0, runtime_1.exists)(json, 'own') ? undefined : (0, Formula_1.FormulaFromJSON)(json['own']),
        'inherited': !(0, runtime_1.exists)(json, 'inherited') ? undefined : (json['inherited'].map(Formula_1.FormulaFromJSON)),
    };
}
exports.ResponseFormulasFromJSONTyped = ResponseFormulasFromJSONTyped;
function ResponseFormulasToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'own': (0, Formula_1.FormulaToJSON)(value.own),
        'inherited': value.inherited === undefined ? undefined : (value.inherited.map(Formula_1.FormulaToJSON)),
    };
}
exports.ResponseFormulasToJSON = ResponseFormulasToJSON;
