"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplatePropsToJSON = exports.TemplatePropsFromJSONTyped = exports.TemplatePropsFromJSON = exports.instanceOfTemplateProps = exports.TemplatePropsStateEnum = void 0;
var runtime_1 = require("../runtime");
var FieldKindPropsValidation_1 = require("./FieldKindPropsValidation");
var PermissionPair_1 = require("./PermissionPair");
var TemplateField_1 = require("./TemplateField");
var TemplatePropsRules_1 = require("./TemplatePropsRules");
var TemplateType_1 = require("./TemplateType");
/**
 * @export
 */
exports.TemplatePropsStateEnum = {
    Paused: 'paused',
    Live: 'live'
};
/**
 * Check if a given object implements the TemplateProps interface.
 */
function instanceOfTemplateProps(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "templateType" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "permissions" in value;
    return isInstance;
}
exports.instanceOfTemplateProps = instanceOfTemplateProps;
function TemplatePropsFromJSON(json) {
    return TemplatePropsFromJSONTyped(json, false);
}
exports.TemplatePropsFromJSON = TemplatePropsFromJSON;
function TemplatePropsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'templateType': (0, TemplateType_1.TemplateTypeFromJSON)(json['templateType']),
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : json['parent'],
        'lineage': !(0, runtime_1.exists)(json, 'lineage') ? undefined : json['lineage'],
        'rules': !(0, runtime_1.exists)(json, 'rules') ? undefined : (0, TemplatePropsRules_1.TemplatePropsRulesFromJSON)(json['rules']),
        'state': json['state'],
        'templateFields': !(0, runtime_1.exists)(json, 'templateFields') ? undefined : (json['templateFields'].map(TemplateField_1.TemplateFieldFromJSON)),
        'permissions': (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'activeness': !(0, runtime_1.exists)(json, 'activeness') ? undefined : (0, FieldKindPropsValidation_1.FieldKindPropsValidationFromJSON)(json['activeness']),
        'dataSourceID': !(0, runtime_1.exists)(json, 'dataSourceID') ? undefined : json['dataSourceID'],
        'autoApprove': !(0, runtime_1.exists)(json, 'autoApprove') ? undefined : json['autoApprove'],
        'autoRemove': !(0, runtime_1.exists)(json, 'autoRemove') ? undefined : json['autoRemove'],
        'autoRevoke': !(0, runtime_1.exists)(json, 'autoRevoke') ? undefined : json['autoRevoke'],
        'hasChildren': !(0, runtime_1.exists)(json, 'hasChildren') ? undefined : json['hasChildren'],
    };
}
exports.TemplatePropsFromJSONTyped = TemplatePropsFromJSONTyped;
function TemplatePropsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'templateType': (0, TemplateType_1.TemplateTypeToJSON)(value.templateType),
        'parent': value.parent,
        'lineage': value.lineage,
        'rules': (0, TemplatePropsRules_1.TemplatePropsRulesToJSON)(value.rules),
        'state': value.state,
        'templateFields': value.templateFields === undefined ? undefined : (value.templateFields.map(TemplateField_1.TemplateFieldToJSON)),
        'permissions': (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'activeness': (0, FieldKindPropsValidation_1.FieldKindPropsValidationToJSON)(value.activeness),
        'dataSourceID': value.dataSourceID,
        'autoApprove': value.autoApprove,
        'autoRemove': value.autoRemove,
        'autoRevoke': value.autoRevoke,
        'hasChildren': value.hasChildren,
    };
}
exports.TemplatePropsToJSON = TemplatePropsToJSON;
