"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateContainerToJSON = exports.CreateContainerFromJSONTyped = exports.CreateContainerFromJSON = exports.instanceOfCreateContainer = void 0;
/**
 * Check if a given object implements the CreateContainer interface.
 */
function instanceOfCreateContainer(value) {
    var isInstance = true;
    isInstance = isInstance && "recipientId" in value;
    isInstance = isInstance && "templateId" in value;
    return isInstance;
}
exports.instanceOfCreateContainer = instanceOfCreateContainer;
function CreateContainerFromJSON(json) {
    return CreateContainerFromJSONTyped(json, false);
}
exports.CreateContainerFromJSON = CreateContainerFromJSON;
function CreateContainerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'recipientId': json['recipient_id'],
        'templateId': json['template_id'],
    };
}
exports.CreateContainerFromJSONTyped = CreateContainerFromJSONTyped;
function CreateContainerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'recipient_id': value.recipientId,
        'template_id': value.templateId,
    };
}
exports.CreateContainerToJSON = CreateContainerToJSON;
