"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchQueryToJSON = exports.SearchQueryFromJSONTyped = exports.SearchQueryFromJSON = exports.instanceOfSearchQuery = exports.SearchQueryStateEnum = exports.SearchQueryTemplateTypeEnum = exports.SearchQueryObjectTypeEnum = void 0;
var runtime_1 = require("../runtime");
var ContainerFieldQuery_1 = require("./ContainerFieldQuery");
/**
 * @export
 */
exports.SearchQueryObjectTypeEnum = {
    Template: 'template',
    Fieldkind: 'fieldkind',
    Datasource: 'datasource',
    Policy: 'policy'
};
/**
 * @export
 */
exports.SearchQueryTemplateTypeEnum = {
    Merit: 'Merit',
    Folio: 'Folio',
    Persona: 'Persona'
};
/**
 * @export
 */
exports.SearchQueryStateEnum = {
    Unissued: 'unissued',
    Pending: 'pending',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Revoked: 'revoked'
};
/**
 * Check if a given object implements the SearchQuery interface.
 */
function instanceOfSearchQuery(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfSearchQuery = instanceOfSearchQuery;
function SearchQueryFromJSON(json) {
    return SearchQueryFromJSONTyped(json, false);
}
exports.SearchQueryFromJSON = SearchQueryFromJSON;
function SearchQueryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'objectType': !(0, runtime_1.exists)(json, 'objectType') ? undefined : json['objectType'],
        'objectName': !(0, runtime_1.exists)(json, 'objectName') ? undefined : json['objectName'],
        'containerFields': !(0, runtime_1.exists)(json, 'containerFields') ? undefined : (json['containerFields'].map(ContainerFieldQuery_1.ContainerFieldQueryFromJSON)),
        'recipientID': !(0, runtime_1.exists)(json, 'recipientID') ? undefined : json['recipientID'],
        'templateID': !(0, runtime_1.exists)(json, 'templateID') ? undefined : json['templateID'],
        'templateType': !(0, runtime_1.exists)(json, 'templateType') ? undefined : json['templateType'],
        'state': !(0, runtime_1.exists)(json, 'state') ? undefined : json['state'],
        'authorizedAtStart': !(0, runtime_1.exists)(json, 'authorized_at_start') ? undefined : json['authorized_at_start'],
        'authorizedAtEnd': !(0, runtime_1.exists)(json, 'authorized_at_end') ? undefined : json['authorized_at_end'],
        'limit': !(0, runtime_1.exists)(json, 'limit') ? undefined : json['limit'],
    };
}
exports.SearchQueryFromJSONTyped = SearchQueryFromJSONTyped;
function SearchQueryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'objectType': value.objectType,
        'objectName': value.objectName,
        'containerFields': value.containerFields === undefined ? undefined : (value.containerFields.map(ContainerFieldQuery_1.ContainerFieldQueryToJSON)),
        'recipientID': value.recipientID,
        'templateID': value.templateID,
        'templateType': value.templateType,
        'state': value.state,
        'authorized_at_start': value.authorizedAtStart,
        'authorized_at_end': value.authorizedAtEnd,
        'limit': value.limit,
    };
}
exports.SearchQueryToJSON = SearchQueryToJSON;
