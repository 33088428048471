"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyResponseToJSON = exports.PolicyResponseFromJSONTyped = exports.PolicyResponseFromJSON = exports.instanceOfPolicyResponse = void 0;
var runtime_1 = require("../runtime");
var PaginationResponseCursor_1 = require("./PaginationResponseCursor");
var Policy_1 = require("./Policy");
/**
 * Check if a given object implements the PolicyResponse interface.
 */
function instanceOfPolicyResponse(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPolicyResponse = instanceOfPolicyResponse;
function PolicyResponseFromJSON(json) {
    return PolicyResponseFromJSONTyped(json, false);
}
exports.PolicyResponseFromJSON = PolicyResponseFromJSON;
function PolicyResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'policies': !(0, runtime_1.exists)(json, 'policies') ? undefined : (json['policies'].map(Policy_1.PolicyFromJSON)),
        'hasMore': !(0, runtime_1.exists)(json, 'hasMore') ? undefined : json['hasMore'],
        'cursor': !(0, runtime_1.exists)(json, 'cursor') ? undefined : (0, PaginationResponseCursor_1.PaginationResponseCursorFromJSON)(json['cursor']),
    };
}
exports.PolicyResponseFromJSONTyped = PolicyResponseFromJSONTyped;
function PolicyResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'policies': value.policies === undefined ? undefined : (value.policies.map(Policy_1.PolicyToJSON)),
        'hasMore': value.hasMore,
        'cursor': (0, PaginationResponseCursor_1.PaginationResponseCursorToJSON)(value.cursor),
    };
}
exports.PolicyResponseToJSON = PolicyResponseToJSON;
