"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateFieldToJSON = exports.TemplateFieldFromJSONTyped = exports.TemplateFieldFromJSON = exports.instanceOfTemplateField = void 0;
var runtime_1 = require("../runtime");
var PermissionPair_1 = require("./PermissionPair");
var TemplateFieldColumnMapping_1 = require("./TemplateFieldColumnMapping");
var TemplateFieldFieldValidation_1 = require("./TemplateFieldFieldValidation");
var TemplateFieldKindInfo_1 = require("./TemplateFieldKindInfo");
/**
 * Check if a given object implements the TemplateField interface.
 */
function instanceOfTemplateField(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "templateID" in value;
    isInstance = isInstance && "fieldKind" in value;
    isInstance = isInstance && "contact" in value;
    isInstance = isInstance && "permissions" in value;
    return isInstance;
}
exports.instanceOfTemplateField = instanceOfTemplateField;
function TemplateFieldFromJSON(json) {
    return TemplateFieldFromJSONTyped(json, false);
}
exports.TemplateFieldFromJSON = TemplateFieldFromJSON;
function TemplateFieldFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'templateID': json['templateID'],
        'lineage': !(0, runtime_1.exists)(json, 'lineage') ? undefined : json['lineage'],
        'fieldKind': (0, TemplateFieldKindInfo_1.TemplateFieldKindInfoFromJSON)(json['fieldKind']),
        'contact': json['contact'],
        'permissions': (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'fieldMapping': !(0, runtime_1.exists)(json, 'fieldMapping') ? undefined : (0, TemplateFieldColumnMapping_1.TemplateFieldColumnMappingFromJSON)(json['fieldMapping']),
        'fieldValidation': !(0, runtime_1.exists)(json, 'fieldValidation') ? undefined : (0, TemplateFieldFieldValidation_1.TemplateFieldFieldValidationFromJSON)(json['fieldValidation']),
    };
}
exports.TemplateFieldFromJSONTyped = TemplateFieldFromJSONTyped;
function TemplateFieldToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'templateID': value.templateID,
        'lineage': value.lineage,
        'fieldKind': (0, TemplateFieldKindInfo_1.TemplateFieldKindInfoToJSON)(value.fieldKind),
        'contact': value.contact,
        'permissions': (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'fieldMapping': (0, TemplateFieldColumnMapping_1.TemplateFieldColumnMappingToJSON)(value.fieldMapping),
        'fieldValidation': (0, TemplateFieldFieldValidation_1.TemplateFieldFieldValidationToJSON)(value.fieldValidation),
    };
}
exports.TemplateFieldToJSON = TemplateFieldToJSON;
