"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateTypeToJSON = exports.TemplateTypeFromJSONTyped = exports.TemplateTypeFromJSON = exports.TemplateType = void 0;
/**
 * Abstract representation of a TemplateType - use one of its implementing children like Merit or Folio.
 * @export
 */
exports.TemplateType = {
    Merit: 'Merit',
    Folio: 'Folio',
    Persona: 'Persona'
};
function TemplateTypeFromJSON(json) {
    return TemplateTypeFromJSONTyped(json, false);
}
exports.TemplateTypeFromJSON = TemplateTypeFromJSON;
function TemplateTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TemplateTypeFromJSONTyped = TemplateTypeFromJSONTyped;
function TemplateTypeToJSON(value) {
    return value;
}
exports.TemplateTypeToJSON = TemplateTypeToJSON;
