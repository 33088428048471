"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContainerListToJSON = exports.ContainerListFromJSONTyped = exports.ContainerListFromJSON = exports.instanceOfContainerList = void 0;
var runtime_1 = require("../runtime");
var Container_1 = require("./Container");
var PaginationResponseCursor_1 = require("./PaginationResponseCursor");
/**
 * Check if a given object implements the ContainerList interface.
 */
function instanceOfContainerList(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfContainerList = instanceOfContainerList;
function ContainerListFromJSON(json) {
    return ContainerListFromJSONTyped(json, false);
}
exports.ContainerListFromJSON = ContainerListFromJSON;
function ContainerListFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'containers': !(0, runtime_1.exists)(json, 'containers') ? undefined : (json['containers'].map(Container_1.ContainerFromJSON)),
        'hasMore': !(0, runtime_1.exists)(json, 'hasMore') ? undefined : json['hasMore'],
        'cursor': !(0, runtime_1.exists)(json, 'cursor') ? undefined : (0, PaginationResponseCursor_1.PaginationResponseCursorFromJSON)(json['cursor']),
    };
}
exports.ContainerListFromJSONTyped = ContainerListFromJSONTyped;
function ContainerListToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'containers': value.containers === undefined ? undefined : (value.containers.map(Container_1.ContainerToJSON)),
        'hasMore': value.hasMore,
        'cursor': (0, PaginationResponseCursor_1.PaginationResponseCursorToJSON)(value.cursor),
    };
}
exports.ContainerListToJSON = ContainerListToJSON;
