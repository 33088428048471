"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaginationCursorToJSON = exports.PaginationCursorFromJSONTyped = exports.PaginationCursorFromJSON = exports.instanceOfPaginationCursor = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the PaginationCursor interface.
 */
function instanceOfPaginationCursor(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPaginationCursor = instanceOfPaginationCursor;
function PaginationCursorFromJSON(json) {
    return PaginationCursorFromJSONTyped(json, false);
}
exports.PaginationCursorFromJSON = PaginationCursorFromJSON;
function PaginationCursorFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'endingBefore': !(0, runtime_1.exists)(json, 'ending_before') ? undefined : json['ending_before'],
        'limit': !(0, runtime_1.exists)(json, 'limit') ? undefined : json['limit'],
        'sortBy': !(0, runtime_1.exists)(json, 'sort_by') ? undefined : json['sort_by'],
        'startingAfter': !(0, runtime_1.exists)(json, 'starting_after') ? undefined : json['starting_after'],
    };
}
exports.PaginationCursorFromJSONTyped = PaginationCursorFromJSONTyped;
function PaginationCursorToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ending_before': value.endingBefore,
        'limit': value.limit,
        'sort_by': value.sortBy,
        'starting_after': value.startingAfter,
    };
}
exports.PaginationCursorToJSON = PaginationCursorToJSON;
