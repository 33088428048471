"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicySearchResultToJSON = exports.PolicySearchResultFromJSONTyped = exports.PolicySearchResultFromJSON = exports.instanceOfPolicySearchResult = exports.PolicySearchResultStateEnum = void 0;
var runtime_1 = require("../runtime");
var PermissionPair_1 = require("./PermissionPair");
var ResponseRules_1 = require("./ResponseRules");
/**
 * @export
 */
exports.PolicySearchResultStateEnum = {
    Live: 'live',
    Paused: 'paused'
};
/**
 * Check if a given object implements the PolicySearchResult interface.
 */
function instanceOfPolicySearchResult(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfPolicySearchResult = instanceOfPolicySearchResult;
function PolicySearchResultFromJSON(json) {
    return PolicySearchResultFromJSONTyped(json, false);
}
exports.PolicySearchResultFromJSON = PolicySearchResultFromJSON;
function PolicySearchResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'owner': !(0, runtime_1.exists)(json, 'owner') ? undefined : json['owner'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'state': !(0, runtime_1.exists)(json, 'state') ? undefined : json['state'],
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : json['parent'],
        'rules': !(0, runtime_1.exists)(json, 'rules') ? undefined : (0, ResponseRules_1.ResponseRulesFromJSON)(json['rules']),
        'permissions': !(0, runtime_1.exists)(json, 'permissions') ? undefined : (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
    };
}
exports.PolicySearchResultFromJSONTyped = PolicySearchResultFromJSONTyped;
function PolicySearchResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'owner': value.owner,
        'description': value.description,
        'state': value.state,
        'parent': value.parent,
        'rules': (0, ResponseRules_1.ResponseRulesToJSON)(value.rules),
        'permissions': value.permissions === undefined ? undefined : (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
    };
}
exports.PolicySearchResultToJSON = PolicySearchResultToJSON;
