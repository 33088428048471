"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PolicyToJSON = exports.PolicyFromJSONTyped = exports.PolicyFromJSON = exports.instanceOfPolicy = exports.PolicyStateEnum = void 0;
var runtime_1 = require("../runtime");
var EntityInfo_1 = require("./EntityInfo");
var PermissionPair_1 = require("./PermissionPair");
var ResponseFormulas_1 = require("./ResponseFormulas");
var ResponseRules_1 = require("./ResponseRules");
/**
 * @export
 */
exports.PolicyStateEnum = {
    Live: 'live',
    Paused: 'paused'
};
/**
 * Check if a given object implements the Policy interface.
 */
function instanceOfPolicy(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "owner" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "falseMessage" in value;
    isInstance = isInstance && "trueMessage" in value;
    isInstance = isInstance && "permissions" in value;
    isInstance = isInstance && "state" in value;
    return isInstance;
}
exports.instanceOfPolicy = instanceOfPolicy;
function PolicyFromJSON(json) {
    return PolicyFromJSONTyped(json, false);
}
exports.PolicyFromJSON = PolicyFromJSON;
function PolicyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'owner': (0, EntityInfo_1.EntityInfoFromJSON)(json['owner']),
        'createdAt': json['createdAt'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'falseMessage': json['falseMessage'],
        'trueMessage': json['trueMessage'],
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : json['parent'],
        'rules': !(0, runtime_1.exists)(json, 'rules') ? undefined : (0, ResponseRules_1.ResponseRulesFromJSON)(json['rules']),
        'formula': !(0, runtime_1.exists)(json, 'formula') ? undefined : (0, ResponseFormulas_1.ResponseFormulasFromJSON)(json['formula']),
        'permissions': (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'state': json['state'],
        'hasChildren': !(0, runtime_1.exists)(json, 'hasChildren') ? undefined : json['hasChildren'],
    };
}
exports.PolicyFromJSONTyped = PolicyFromJSONTyped;
function PolicyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'owner': (0, EntityInfo_1.EntityInfoToJSON)(value.owner),
        'createdAt': value.createdAt,
        'name': value.name,
        'description': value.description,
        'falseMessage': value.falseMessage,
        'trueMessage': value.trueMessage,
        'parent': value.parent,
        'rules': (0, ResponseRules_1.ResponseRulesToJSON)(value.rules),
        'formula': (0, ResponseFormulas_1.ResponseFormulasToJSON)(value.formula),
        'permissions': (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'state': value.state,
        'hasChildren': value.hasChildren,
    };
}
exports.PolicyToJSON = PolicyToJSON;
