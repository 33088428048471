"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetConfigurationResponseToJSON = exports.GetConfigurationResponseFromJSONTyped = exports.GetConfigurationResponseFromJSON = exports.instanceOfGetConfigurationResponse = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the GetConfigurationResponse interface.
 */
function instanceOfGetConfigurationResponse(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfGetConfigurationResponse = instanceOfGetConfigurationResponse;
function GetConfigurationResponseFromJSON(json) {
    return GetConfigurationResponseFromJSONTyped(json, false);
}
exports.GetConfigurationResponseFromJSON = GetConfigurationResponseFromJSON;
function GetConfigurationResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accountMeritTemplateID': !(0, runtime_1.exists)(json, 'accountMeritTemplateID') ? undefined : json['accountMeritTemplateID'],
        'adminMeritTemplateID': !(0, runtime_1.exists)(json, 'adminMeritTemplateID') ? undefined : json['adminMeritTemplateID'],
        'solEntityID': !(0, runtime_1.exists)(json, 'solEntityID') ? undefined : json['solEntityID'],
        'launchDarklyClientID': !(0, runtime_1.exists)(json, 'launchDarklyClientID') ? undefined : json['launchDarklyClientID'],
        'launchDarklyMobileKey': !(0, runtime_1.exists)(json, 'launchDarklyMobileKey') ? undefined : json['launchDarklyMobileKey'],
    };
}
exports.GetConfigurationResponseFromJSONTyped = GetConfigurationResponseFromJSONTyped;
function GetConfigurationResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accountMeritTemplateID': value.accountMeritTemplateID,
        'adminMeritTemplateID': value.adminMeritTemplateID,
        'solEntityID': value.solEntityID,
        'launchDarklyClientID': value.launchDarklyClientID,
        'launchDarklyMobileKey': value.launchDarklyMobileKey,
    };
}
exports.GetConfigurationResponseToJSON = GetConfigurationResponseToJSON;
