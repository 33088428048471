"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllToJSON = exports.AllFromJSONTyped = exports.AllFromJSON = exports.instanceOfAll = void 0;
/**
 * Check if a given object implements the All interface.
 */
function instanceOfAll(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfAll = instanceOfAll;
function AllFromJSON(json) {
    return AllFromJSONTyped(json, false);
}
exports.AllFromJSON = AllFromJSON;
function AllFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.AllFromJSONTyped = AllFromJSONTyped;
function AllToJSON(value) {
    return value;
}
exports.AllToJSON = AllToJSON;
