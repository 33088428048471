"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateFieldKindInfoToJSON = exports.TemplateFieldKindInfoFromJSONTyped = exports.TemplateFieldKindInfoFromJSON = exports.instanceOfTemplateFieldKindInfo = void 0;
var runtime_1 = require("../runtime");
var FieldKindPropsValidation_1 = require("./FieldKindPropsValidation");
var FieldType_1 = require("./FieldType");
/**
 * Check if a given object implements the TemplateFieldKindInfo interface.
 */
function instanceOfTemplateFieldKindInfo(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
exports.instanceOfTemplateFieldKindInfo = instanceOfTemplateFieldKindInfo;
function TemplateFieldKindInfoFromJSON(json) {
    return TemplateFieldKindInfoFromJSONTyped(json, false);
}
exports.TemplateFieldKindInfoFromJSON = TemplateFieldKindInfoFromJSON;
function TemplateFieldKindInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'fieldType': !(0, runtime_1.exists)(json, 'fieldType') ? undefined : (0, FieldType_1.FieldTypeFromJSON)(json['fieldType']),
        'validation': !(0, runtime_1.exists)(json, 'validation') ? undefined : (0, FieldKindPropsValidation_1.FieldKindPropsValidationFromJSON)(json['validation']),
    };
}
exports.TemplateFieldKindInfoFromJSONTyped = TemplateFieldKindInfoFromJSONTyped;
function TemplateFieldKindInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'fieldType': (0, FieldType_1.FieldTypeToJSON)(value.fieldType),
        'validation': (0, FieldKindPropsValidation_1.FieldKindPropsValidationToJSON)(value.validation),
    };
}
exports.TemplateFieldKindInfoToJSON = TemplateFieldKindInfoToJSON;
