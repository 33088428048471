"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplatePropsRulesToJSON = exports.TemplatePropsRulesFromJSONTyped = exports.TemplatePropsRulesFromJSON = exports.instanceOfTemplatePropsRules = void 0;
var runtime_1 = require("../runtime");
var ResponseRules_1 = require("./ResponseRules");
/**
 * Check if a given object implements the TemplatePropsRules interface.
 */
function instanceOfTemplatePropsRules(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfTemplatePropsRules = instanceOfTemplatePropsRules;
function TemplatePropsRulesFromJSON(json) {
    return TemplatePropsRulesFromJSONTyped(json, false);
}
exports.TemplatePropsRulesFromJSON = TemplatePropsRulesFromJSON;
function TemplatePropsRulesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'completeness': !(0, runtime_1.exists)(json, 'completeness') ? undefined : (0, ResponseRules_1.ResponseRulesFromJSON)(json['completeness']),
        'activeness': !(0, runtime_1.exists)(json, 'activeness') ? undefined : (0, ResponseRules_1.ResponseRulesFromJSON)(json['activeness']),
    };
}
exports.TemplatePropsRulesFromJSONTyped = TemplatePropsRulesFromJSONTyped;
function TemplatePropsRulesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'completeness': (0, ResponseRules_1.ResponseRulesToJSON)(value.completeness),
        'activeness': (0, ResponseRules_1.ResponseRulesToJSON)(value.activeness),
    };
}
exports.TemplatePropsRulesToJSON = TemplatePropsRulesToJSON;
