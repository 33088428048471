"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddTemplateFieldPropertiesToJSON = exports.AddTemplateFieldPropertiesFromJSONTyped = exports.AddTemplateFieldPropertiesFromJSON = exports.instanceOfAddTemplateFieldProperties = void 0;
var runtime_1 = require("../runtime");
var Atom_1 = require("./Atom");
var PermissionPair_1 = require("./PermissionPair");
var TemplateFieldModification_1 = require("./TemplateFieldModification");
/**
 * Check if a given object implements the AddTemplateFieldProperties interface.
 */
function instanceOfAddTemplateFieldProperties(value) {
    var isInstance = true;
    isInstance = isInstance && "fieldKindID" in value;
    return isInstance;
}
exports.instanceOfAddTemplateFieldProperties = instanceOfAddTemplateFieldProperties;
function AddTemplateFieldPropertiesFromJSON(json) {
    return AddTemplateFieldPropertiesFromJSONTyped(json, false);
}
exports.AddTemplateFieldPropertiesFromJSON = AddTemplateFieldPropertiesFromJSON;
function AddTemplateFieldPropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return __assign(__assign({}, (0, TemplateFieldModification_1.TemplateFieldModificationFromJSONTyped)(json, ignoreDiscriminator)), { 'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'], 'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'], 'contact': !(0, runtime_1.exists)(json, 'contact') ? undefined : json['contact'], 'permissions': !(0, runtime_1.exists)(json, 'permissions') ? undefined : (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)), 'fieldValidationRules': !(0, runtime_1.exists)(json, 'fieldValidationRules') ? undefined : (json['fieldValidationRules'].map(Atom_1.AtomFromJSON)), 'fieldKindID': json['fieldKindID'] });
}
exports.AddTemplateFieldPropertiesFromJSONTyped = AddTemplateFieldPropertiesFromJSONTyped;
function AddTemplateFieldPropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return __assign(__assign({}, (0, TemplateFieldModification_1.TemplateFieldModificationToJSON)(value)), { 'name': value.name, 'description': value.description, 'contact': value.contact, 'permissions': value.permissions === undefined ? undefined : (value.permissions.map(PermissionPair_1.PermissionPairToJSON)), 'fieldValidationRules': value.fieldValidationRules === undefined ? undefined : (value.fieldValidationRules.map(Atom_1.AtomToJSON)), 'fieldKindID': value.fieldKindID });
}
exports.AddTemplateFieldPropertiesToJSON = AddTemplateFieldPropertiesToJSON;
