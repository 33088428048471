"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateFieldColumnMappingPropertiesToJSON = exports.TemplateFieldColumnMappingPropertiesFromJSONTyped = exports.TemplateFieldColumnMappingPropertiesFromJSON = exports.instanceOfTemplateFieldColumnMappingProperties = exports.TemplateFieldColumnMappingPropertiesMetadataMappingEnum = void 0;
var runtime_1 = require("../runtime");
/**
 * @export
 */
exports.TemplateFieldColumnMappingPropertiesMetadataMappingEnum = {
    RecipientName: 'recipientName'
};
/**
 * Check if a given object implements the TemplateFieldColumnMappingProperties interface.
 */
function instanceOfTemplateFieldColumnMappingProperties(value) {
    var isInstance = true;
    isInstance = isInstance && "templateFieldID" in value;
    return isInstance;
}
exports.instanceOfTemplateFieldColumnMappingProperties = instanceOfTemplateFieldColumnMappingProperties;
function TemplateFieldColumnMappingPropertiesFromJSON(json) {
    return TemplateFieldColumnMappingPropertiesFromJSONTyped(json, false);
}
exports.TemplateFieldColumnMappingPropertiesFromJSON = TemplateFieldColumnMappingPropertiesFromJSON;
function TemplateFieldColumnMappingPropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'templateFieldID': json['templateFieldID'],
        'columnID': !(0, runtime_1.exists)(json, 'columnID') ? undefined : json['columnID'],
        'containerID': !(0, runtime_1.exists)(json, 'containerID') ? undefined : json['containerID'],
        'containerFieldID': !(0, runtime_1.exists)(json, 'containerFieldID') ? undefined : json['containerFieldID'],
        'metadataMapping': !(0, runtime_1.exists)(json, 'metadataMapping') ? undefined : json['metadataMapping'],
    };
}
exports.TemplateFieldColumnMappingPropertiesFromJSONTyped = TemplateFieldColumnMappingPropertiesFromJSONTyped;
function TemplateFieldColumnMappingPropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'templateFieldID': value.templateFieldID,
        'columnID': value.columnID,
        'containerID': value.containerID,
        'containerFieldID': value.containerFieldID,
        'metadataMapping': value.metadataMapping,
    };
}
exports.TemplateFieldColumnMappingPropertiesToJSON = TemplateFieldColumnMappingPropertiesToJSON;
