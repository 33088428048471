"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateFieldPatchToJSON = exports.TemplateFieldPatchFromJSONTyped = exports.TemplateFieldPatchFromJSON = exports.instanceOfTemplateFieldPatch = void 0;
var runtime_1 = require("../runtime");
var Atom_1 = require("./Atom");
var PermissionPair_1 = require("./PermissionPair");
/**
 * Check if a given object implements the TemplateFieldPatch interface.
 */
function instanceOfTemplateFieldPatch(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfTemplateFieldPatch = instanceOfTemplateFieldPatch;
function TemplateFieldPatchFromJSON(json) {
    return TemplateFieldPatchFromJSONTyped(json, false);
}
exports.TemplateFieldPatchFromJSON = TemplateFieldPatchFromJSON;
function TemplateFieldPatchFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'contact': !(0, runtime_1.exists)(json, 'contact') ? undefined : json['contact'],
        'permissions': !(0, runtime_1.exists)(json, 'permissions') ? undefined : (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'fieldValidationRules': !(0, runtime_1.exists)(json, 'fieldValidationRules') ? undefined : (json['fieldValidationRules'].map(Atom_1.AtomFromJSON)),
    };
}
exports.TemplateFieldPatchFromJSONTyped = TemplateFieldPatchFromJSONTyped;
function TemplateFieldPatchToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'contact': value.contact,
        'permissions': value.permissions === undefined ? undefined : (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'fieldValidationRules': value.fieldValidationRules === undefined ? undefined : (value.fieldValidationRules.map(Atom_1.AtomToJSON)),
    };
}
exports.TemplateFieldPatchToJSON = TemplateFieldPatchToJSON;
