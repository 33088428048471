"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * member
 * Member API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfirmTOSRequestToJSON = exports.ConfirmTOSRequestFromJSONTyped = exports.ConfirmTOSRequestFromJSON = exports.instanceOfConfirmTOSRequest = void 0;
/**
 * Check if a given object implements the ConfirmTOSRequest interface.
 */
function instanceOfConfirmTOSRequest(value) {
    var isInstance = true;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "tosAccepted" in value;
    isInstance = isInstance && "ppAccepted" in value;
    return isInstance;
}
exports.instanceOfConfirmTOSRequest = instanceOfConfirmTOSRequest;
function ConfirmTOSRequestFromJSON(json) {
    return ConfirmTOSRequestFromJSONTyped(json, false);
}
exports.ConfirmTOSRequestFromJSON = ConfirmTOSRequestFromJSON;
function ConfirmTOSRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'state': json['state'],
        'token': json['token'],
        'tosAccepted': json['tosAccepted'],
        'ppAccepted': json['ppAccepted'],
    };
}
exports.ConfirmTOSRequestFromJSONTyped = ConfirmTOSRequestFromJSONTyped;
function ConfirmTOSRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'state': value.state,
        'token': value.token,
        'tosAccepted': value.tosAccepted,
        'ppAccepted': value.ppAccepted,
    };
}
exports.ConfirmTOSRequestToJSON = ConfirmTOSRequestToJSON;
