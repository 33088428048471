"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Issuance
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldKindPatchToJSON = exports.FieldKindPatchFromJSONTyped = exports.FieldKindPatchFromJSON = exports.instanceOfFieldKindPatch = void 0;
var runtime_1 = require("../runtime");
var Atom_1 = require("./Atom");
var PermissionPair_1 = require("./PermissionPair");
/**
 * Check if a given object implements the FieldKindPatch interface.
 */
function instanceOfFieldKindPatch(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfFieldKindPatch = instanceOfFieldKindPatch;
function FieldKindPatchFromJSON(json) {
    return FieldKindPatchFromJSONTyped(json, false);
}
exports.FieldKindPatchFromJSON = FieldKindPatchFromJSON;
function FieldKindPatchFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'permissions': !(0, runtime_1.exists)(json, 'permissions') ? undefined : (json['permissions'].map(PermissionPair_1.PermissionPairFromJSON)),
        'ruleValid': !(0, runtime_1.exists)(json, 'ruleValid') ? undefined : (json['ruleValid'].map(Atom_1.AtomFromJSON)),
    };
}
exports.FieldKindPatchFromJSONTyped = FieldKindPatchFromJSONTyped;
function FieldKindPatchToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'permissions': value.permissions === undefined ? undefined : (value.permissions.map(PermissionPair_1.PermissionPairToJSON)),
        'ruleValid': value.ruleValid === undefined ? undefined : (value.ruleValid.map(Atom_1.AtomToJSON)),
    };
}
exports.FieldKindPatchToJSON = FieldKindPatchToJSON;
